import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/seo';

var NotFoundPage = function NotFoundPage() {
  return ___EmotionJSX(Layout, null, ___EmotionJSX(SEO, {
    title: "404: Not found"
  }), ___EmotionJSX("h1", null, "NOT FOUND"), ___EmotionJSX("p", null, "You just hit a route that doesn't exist... the sadness."));
};

export default NotFoundPage;